/* eslint-disable max-lines */
import {createRouter, createWebHashHistory, RouteLocation, RouteLocationRaw} from "vue-router";
import { Season, UserState } from '@busy-human/hxp-library';
import { useTitle } from "./store/title";
import { useAuth } from "./store/auth";
import { FeatureFlags } from "@busy-human/vue-component-library";
import { useBuilderPortalStore } from "./store/builder-portal";
import { useBuilderStore } from "./store/builder";
import { ref, watch, WatchStopHandle } from "vue";
import { useSeasons } from "./store/seasons";
import { useSeasonFeature } from "./composables/useSeasonFeature";
import emitter from '@/events';


const ShoppingHome = () => import("./views/shopping/shopping-home.vue");
const TripView = () => import('./views/shopping/trip-details.vue');
const ShoppingCart = () => import('./views/shopping/shopping-cart.vue');

const RegistrationHome = () => import("./views/registration/registration-home.vue");
const LandingPage = () => import("./views/registration/landing.vue");
//const BuildersInfo = () => import("./views/registration/builders-info.vue");
const RegistrationBuilderSInfo = ()=> import("./views/Registration/registration-portal-builder-info.vue");
const BuilderPortalBuildersInfo = ()=> import("./views/Builder/builder-portal-builder-info.vue");



const ParentInfo = () => import("./views/registration/parent-info-registration.vue");

const FirstExperience = () => import('./views/registration/firstExperience.vue');
const PoliciesPage = () => import('./views/registration/policies2.vue');
const PreferencesPage = () => import('./views/registration/preferences.vue');
const PaymentPage = () => import('./views/registration/payment.vue');

const BuilderHome = () => import("./views/builder/builder-home.vue");
const CustodyPage = () => import("./views/Builder/custody-agreement.vue");

const BuilderDashboard = () => import("./views/builder/builder-dashboard.vue");
const FullChecklist = () => import("./views/builder/checklistItems/full-checklist.vue");
//const BuilderInfo = () => import('./views/builder/builder-info.vue');
//Builder Packet
const Passport = () => import('./views/builder/passport.vue');
const ParentGuardianInfo = () => import("./views/builder/checklistItems/parent-info-builder.vue");
const EmergencyContacts = () => import ("./views/builder/checklistItems/emergency-contact.vue");
const AllAboutYou = () => import("./views/builder/checklistItems/all-about-you.vue");
const NotarizedAuthorization = () => import("./views/builder/checklistItems/notarized-auth.vue");
const BuilderPrepVideos = () => import("./views/Builder/checklistItems/builder-prep-vids.vue");
//const HealthForm = () => import("./views/Builder/checklistItems/health-form.vue");
const HealthForm = () => import("./views/Builder/health-forms.vue");
const HealthDocuments = () => import("./views/Builder/health-documents.vue");
const GroupMeInfo = () => import("./views/builder/checklistItems/groupme-info.vue");
const FlightInformation = () => import("./views/builder/checklistItems/flight-information.vue");
const Visa = () => import("./views/builder/checklistItems/visa.vue");
const Household = () => import("./views/Builder/household.vue");
const TripPayments = () => import("./views/Builder/trip-payment.vue");
const YouthBuilderPacket = () => import("./components/checklist-items/youth-builder.vue");
const ParentBuilderPacket = () => import("./components/checklist-items/parent-builder.vue");
const AtHomeParentPacket = () => import("./components/checklist-items/parent-at-home.vue");
const ParentAtHomeInfo = () => import("./views/Builder/parent-at-home-info.vue");
const VaccineInfo = () => import("./views/Builder/checklistItems/vaccine.vue");

const AdminHome = () => import("./views/admin/admin-home.vue");

//For your parent -> Parent Packet
//For your parent -> Info Page
//Parent Builder -> Parent Builder Packet
const BuilderPortalParentBuilderInfo = () => import("./views/Builder/parent-builder-info.vue");
const BuilderPortalParentBackground = () => import("./views/Builder/parent-background.vue");
const BuilderPortalParentTraining = () => import("./views/Builder/parent-training.vue");
//Parent Builder -> Info Page
const BuilderPortalPolicies = () => import("./views/Builder/policies.vue");
const ManageYourTrip = () => import("./views/Builder/manage-trip.vue");
const LetterPage = () => import('./views/builder/ceo-letter2.vue');
//Policies

//Old Builder Dashboard pages
const YourTrip = () =>  import("./views/builder/your-trip.vue");
const CheckList = () =>  import("./views/builder/checklist.vue");
const PrepVideo = () => import("./components/builder-dashboard/prep-video.vue");
// const HealthInformation = () => import("./views/Builder/checklistItems/health-information.vue");
const DomesticWaivers = () => import("./views/builder/checklistItems/domestic-waivers.vue");
const DoctorsHealthForm = () => import("./views/Builder/checklistItems/doctors-health-form.vue");
// const Booked = () => import("./views/booked.vue");
const Donations = () => import("./views/donations.vue");


const router = createRouter({
    // HTML5 history, no more hash # (README: Vue Router > URL Structure)
    history: createWebHashHistory(),
    routes: [
        //Shopping Views
        {
            path: "/shopping-home",
            component: ShoppingHome,
            name: "Shopping Home",
            meta: {
                navbar: 'shopping',
                userstage: UserState.BuilderStage.SHOPPING
            },
            children: [
                {
                    path: ':id',
                    component: TripView,
                    name: "Trip Detail",
                    props: true,
                    meta: {
                        userstage: UserState.BuilderStage.SHOPPING
                    }
                }
            ]
        },
        {
            path: '/',
            redirect: routeToFurthestPossiblePage
        },

        {
            path: "/shopping-cart",
            component: ShoppingCart,
            name: "Shopping Cart",
            meta: {
                navbar: 'shopping',
                userstage: UserState.BuilderStage.SHOPPING
            }
        },

        //Registration Views
        {
            path: "/registration",
            component: RegistrationHome,
            name: "Registration Home",
            meta: {
                navbar: 'registration'
            },
            redirect: _to => '/registration/landing',
            children: [
                {
                    path: 'landing',
                    component: LandingPage,
                    name: "Registration Landing",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                },
                {
                    path: 'first-experience',
                    component: FirstExperience,
                    name: "First Experience",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                },
                {
                    path: 'preferences',
                    component: PreferencesPage,
                    name: "Registration Preferences",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                },
                {
                    path: 'policies',
                    component: PoliciesPage,
                    name: "Registration Policies",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                },
                {
                    path: 'payment',
                    component: PaymentPage,
                    name: "Registration Payment",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                },
                {
                    path: "builder-info",
                    component: RegistrationBuilderSInfo,
                    name: "Registration Builders Info",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                },
                {
                    path: "parent-info",
                    component: ParentInfo,
                    name: "Registration Parent Info",
                    meta: {
                        userstage: UserState.BuilderStage.REGISTRATION
                    }
                }
            ]
        },

        //Builder Views
        {
            path: "/builder",
            component: BuilderHome,
            name: "Builder Home",
            meta: {
                navbar: 'builder'
            },
            redirect: _to => '/builder/dashboard',
            children: [
                //======= Main Links =========
                {
                    path: 'dashboard',
                    component: BuilderDashboard,
                    name: "Builder Dashboard",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    }
                },
                {
                    path: 'ceoLetter',
                    component: LetterPage,
                    name: "Letter From CEO",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    }
                },

                {
                    path: 'builderInfo',
                    component: BuilderPortalBuildersInfo,
                    name: 'Builder Info',
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    }
                },
                {
                    path: 'tripPayment',
                    component: TripPayments,
                    name: "Trip Payments",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    }
                },
                {
                    path: "policies",
                    component: BuilderPortalPolicies,
                    name: "Policies",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "main-links"
                    }
                },
                {
                    path: 'tripManagement',
                    component: ManageYourTrip,
                    name: "Manage Your Trip",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    },
                },
                {
                    path: "household",
                    component: Household,
                    name: "Household",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    }
                },
                {
                    path: "parentAtHomeInfo",
                    component: ParentAtHomeInfo,
                    name: "Parent at Home Info",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                    }
                },
                {
                    path: "shopping-redirect",
                    redirect: "/shopping-home"
                },

                //======= Checklist =========

                {
                    path: "checklistStatus",
                    component: FullChecklist,
                    name: "FullChecklist",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "passport",
                    component: Passport,
                    name: "Passport",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },

                {
                    path: "youthBuilderPacket",
                    component: YouthBuilderPacket,
                    name: "Youth Builder Packet",
                    meta: {
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "parentBuilderPacket",
                    component: ParentBuilderPacket,
                    name: "Parent Builder Packet",
                    meta: {
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "AtHomeParentPacket",
                    component: AtHomeParentPacket,
                    name: "Parent at Home Packet",
                    meta: {
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "parentInfo",
                    component: ParentGuardianInfo,
                    name: "Parent Info",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }

                },
                {
                    path: "emergencyContacts",
                    component: EmergencyContacts,
                    name: "Emergency Contacts",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "builderPrepVideos",
                    component: BuilderPrepVideos,
                    name: "Builder Prep Videos",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "allAboutYou",
                    component: AllAboutYou,
                    name: "All About You",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "notarizedAuthorization",
                    component: NotarizedAuthorization,
                    name: "NotarizedAuthorization",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "healthForm",
                    component: HealthForm,
                    name: "Health Form",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "healthDocuments",
                    component: HealthDocuments,
                    name: "Health Documents",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "groupMe",
                    component: GroupMeInfo,
                    name: "Group Me",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path: "flightInformation",
                    component:FlightInformation,
                    name: "Flight Information",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path:"vaccines",
                    component: VaccineInfo,
                    name: "Vaccines",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }

                },
                {
                    path: "visa",
                    component: Visa,
                    name: "Visa",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }
                },
                {
                    path:"parentBuilderInfo",
                    component: BuilderPortalParentBuilderInfo,
                    name: "Parent Builder Info",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }

                },
                {
                    path:"parentTraining",
                    component: BuilderPortalParentTraining,
                    name: "Parent Training",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }

                },
                {
                    path:"parentBackgroundCheck",
                    component: BuilderPortalParentBackground,
                    name: "Parent Builder Background Check",
                    meta:{
                        userstage: UserState.BuilderStage.BUILDER,
                        section: "checklist"
                    }

                },


                //======= For Your Parent =========

                //======= Parent Builder =========
            ]

        },

        // Admin Views
        {
            path: "/admin",
            name: "Admin Home",
            component: AdminHome,
            meta: {
                requiresAdmin: true,
                navbar: 'admin',
                title: "Admin"
            },
            children: [
                {
                    name: "Lottery Home",
                    component: () => import("./views/admin/lottery-home.vue"),
                    path: "lottery",
                    children: [
                        {
                            name: "Lottery View",
                            component: () => import("./views/admin/lottery-view.vue"),
                            path: ":lotteryId",
                            props: true
                        }
                    ]
                },
                {
                    name: "New Lottery",
                    component: () => import("./views/admin/lottery-new.vue"),
                    path: "lottery/new"
                },

                {
                    name: "Trip Reassignment",
                    component: () => import("./views/admin/trip-reassignment.vue"),
                    path: "trip-reassignment"
                },
                {
                    name: "Create Builder",
                    component: () => import("./views/admin/create-builder.vue"),
                    path: "create-builder"
                },
                {
                    name: "Transfer Transaction",
                    component: () => import("./views/admin/transaction-transfer.vue"),
                    path: "transfer-transaction"
                },
                {
                    name: "Change Password",
                    component: () => import("./views/admin/password-change.vue"),
                    path: "password-change"
                },
                {
                    name: "Test Lottery",
                    component: () => import("./views/admin/test-lottery.vue"),
                    path: "test-lottery"
                },
                {
                    name: "Season Management",
                    component: () => import("./views/admin/season-management.vue"),
                    path: "seasons"
                },{
                    name: "Parent Trainings",
                    component: ()=> import("./views/admin/parent-trainings.vue"),
                    path: "parent-trainings"
                }, {
                    name: "Advanced Duo Tool",
                    component: () => import("./views/admin/advanced-duo-tool.vue"),
                    path: "duo-tool"
                }, {
					name: "Regenerate Charges",
					component: () => import("./views/admin/regenerate-charges.vue"),
					path: "regenerate-charges"
				}
            ]
        },
        //{
        //    path: "/builder-home",
        //    component: BuilderHome,
        //    name: "Builder Home",
        //    meta: {
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/builder-dashboard",
        //    component: BuilderDashboard,
        //    name: "Builder Dashboard",
        //    meta: {
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path:'/letter',
        //    component: LetterPage,
        //    name: 'Letter Page',
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/prep-video",
        //    component: PrepVideo,
        //    name: "Prep Video",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/trip-payment",
        //    component: TripPayments,
        //    name: "Trip Payments",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/check-list",
        //    component: CheckList,
        //    name: "CheckList",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/builder-info",
        //    component: BuilderInfo,
        //    name: "Builder Info",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/your-trip",
        //    component: YourTrip,
        //    name: "Your Trip",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/passport",
        //    component: Passport,
        //    name: "Passport",
        //    meta: {
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/emergency-contact",
        //    component:EmergencyContact,
        //    name: "Emergency Contact",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/all-about-you",
        //    component: AllAboutYou,
        //    name: "All About You",
        //    meta:{
        //        navbar:'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/parent-guardian-info",
        //    component: ParentGuardianInfo,
        //    name: "Parent Guardian Information",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/groupme-info",
        //    component: GroupMeInfo,
        //    name: "GroupMe Information",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/domestic-waivers",
        //    component: DomesticWaivers,
        //    name: "Domestic Waivers",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},

        //{
        //    path: "/flight-information",
        //    component: FlightInformation,
        //    name: "FlightInformation",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/health-information",
        //    component: HealthInformation,
        //    name: "healthInfo",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/health-form",
        //    component: HealthForm,
        //    name: "healthForm",
        //    meta:{
        //        userstage: UserState.BuilderStage.BUILDER
        //    }
        //},
        //{
        //    path: "/doctors-health-form/:userId",
        //    component: DoctorsHealthForm,
        //    name: "doctorsHealthForm",
        //    props: route => ({ userId: route.params.userId })
        //},
        //{
        //    path: "/booked",
        //    component: Booked,
        //    name: "booked",
        //    meta:{
        //        navbar: 'builder',
        //        userstage: UserState.BuilderStage.REGISTRATION
        //    }
        //},

        {
           path: "/donations/:donationId",
           component: Donations,
           name: "donations",
           meta: {
            dontShowPopup: true
           }
        },

        // 404 MUST BE LAST PAGE!!!
        {
            path: '/:catchAll(.*)',
            name: 'Not Found',
            redirect: routeToFurthestPossiblePage
        }
    ]
});

const stageDefaults: {[stage in UserState.BuilderStage]: RouteLocationRaw} = {
    'shopping': { name: 'Shopping Home'},
    'registration': { name: 'Registration Home'},
    'builder': { name: 'Builder Dashboard'}
};

router.beforeEach(async (to) => {
    const auth = useAuth();
    await auth.waitForReady();

    if(to.meta.requiresAdmin === true && !auth.isAdmin) return stageDefaults.shopping;
});

export function routeToFurthestPossiblePage(){
    const registrationEnabled = useSeasonFeature(Season.Features.REGISTRATION);
    const checklistEnabled = useSeasonFeature(Season.Features.CHECKLIST);
    const builders = useBuilderStore();
    const builderPortal = useBuilderPortalStore();
    // eslint-disable-next-line no-nested-ternary
    const registrationStage = (registrationEnabled.value !== Season.FeatureState.DISABLED ? stageDefaults.registration : stageDefaults.shopping);
    const checklistStage = checklistEnabled.value !== Season.FeatureState.DISABLED ? stageDefaults.builder : registrationStage;

    builderPortal.waitForReady().then(() => {
        let defaultPage = stageDefaults.shopping;
        builders.asArray.forEach((builder) => {
            if(defaultPage !== stageDefaults.builder){
                const record = builders.builderRecords[builder.$id];
                if(record){
                    if(record.onWaitlist || record.tripAssigned){
                        defaultPage = checklistStage;
                    }else if(record.zohoBuilderId){
                        defaultPage = registrationStage;
                    }
                }
            }
        });
        if(defaultPage !== stageDefaults.shopping){
            router.push(defaultPage);
        }
    });

    return stageDefaults.shopping;
}

const watcherUnsub = ref<WatchStopHandle | null>(null);

router.beforeEach(async (to, from) => {

    // scroll user back to the top of the page
    window.scrollTo(0, 0);

    if(watcherUnsub.value?.()){
        watcherUnsub.value();
        watcherUnsub.value = null;
    }

    if((to.fullPath === "/shopping-home" || to.fullPath === "/") && from.fullPath === "/"){
        routeToFurthestPossiblePage();
        return;
    }

    if(to.path.indexOf("/builder") === 0) {

        if(!FeatureFlags.isFeatureEnabled("BUILDER_CHECKLIST") && (await FeatureFlags.isFeatureEnabled({ feature: "BUILDER_CHECKLIST", context: "beta" }))){
            return false;
        }

        //If they're not a beta user, we won't navguard
        if(!(await FeatureFlags.isFeatureEnabled({ feature: "BUILDER_CHECKLIST", context: "beta" }))){
            return true;
        }

        initWatcher();

        const builderPortal = useBuilderPortalStore();
        await builderPortal.waitForReady();

        const currentPath = to.path.split('/builder/')?.[1].split('/')?.[0];

        return  builderPortal.currentBuilderCanAccessPage(currentPath);
        //return true || builderPortal.currentBuilderCanAccessPage(currentPath);
    } else if(to.path.indexOf("/registration") === 0){
        const season = useSeasons();
        await season.waitForReady();
        const registrationEnabled = useSeasonFeature(Season.Features.REGISTRATION);
        if(registrationEnabled.value === Season.FeatureState.DISABLED){
            router.push('/shopping-home');
        }
        return true;
    }else{
        return true;
    }

    function initWatcher(){
        const builderStore = useBuilderStore();

        watcherUnsub.value = watch(() => builderStore.currentBuilder?.$id, (builderId: string | undefined) => {
            if(!builderId) return;

            const currentRoute = router.currentRoute.value;

            const builderPortal = useBuilderPortalStore();

            if(currentRoute.path.indexOf("/builder") === 0){
                const currentPath = currentRoute.path.split('/builder/')?.[1].split('/')?.[0];
                for (let i = 0; i < builderStore.withRecordAsArray.length; i++) {
                    if(!builderPortal.currentBuilderCanAccessPage(currentPath)){
                        // eslint-disable-next-line max-depth
                        if(builderPortal.currentBuilderCanAccessPage('dashboard')){
                            router.push('/builder/dashboard');
                        }else{
                            router.push('/shopping-home');
                        }
                    }
                    if(currentPath === 'dashboard'){
                        break;
                    }
                }
            }

            emitter.emit('openWelcomeBack');

        });
    }
});

const StageTitleMap: Record<UserState.BuilderStage, string> = {
    [UserState.BuilderStage.SHOPPING]: "Humanitarian Experience",
    [UserState.BuilderStage.REGISTRATION]: "Registration Portal",
    [UserState.BuilderStage.BUILDER]: "Builder Portal"
};

router.afterEach((to) => {
    if(to.meta.title) {
        useTitle().title = to.meta.title as string;
    } else if(to.meta.userstage) {
        const state = to.meta.userstage as UserState.BuilderStage;
        const newTitle = StageTitleMap[state];
        useTitle().title = newTitle;
    }
});

export default router;

